import App from 'next/app';

import React from 'react';
import Head from 'next/head'

import { ConfigProvider } from 'antd';

import '$DATA/Less/merge.less';

export default class MyApp extends App {
	render(){
		const { Component, pageProps } = this.props;
		return <>
			<Head>
				<title>แทงบอล สล็อต หวย และคาสิโน กับ</title>
			</Head>
			<Component { ...pageProps } />
		</>
	}
}
